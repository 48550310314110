import React from "react"
import { Box, Typography } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
function OpenSourceSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          height: { xs: "186px", sm: "120px", md: "319px" },
          width: { xs: "140px", sm: "96px", md: "240px" },
        }}
      >
        <StaticImage
          src={"../../images/OpenSourceBox.png"}
          alt="Logo"
          objectFit="contain"
          style={{
            width: "100%",
            height: "100%",
            "@media screen and (min-width: 768px)": {
              width: "40px",
              height: "22px",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          marginTop: { xs: 5, sm: 2, md: 4 },
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            marginRight: 1,
            fontFamily: "Roboto Condensed",
            fontWeight: 700,
            color: "#383D3B",
            fontSize: { xs: "25px", sm: "24px", md: "60px" },
          }}
        >
          All of the content is
        </Typography>
        <Typography
          sx={{
            fontFamily: "Roboto Condensed",
            color: "#163BBF",
            fontWeight: 700,
            fontSize: { xs: "25px", sm: "24px", md: "60px" },
          }}
        >
          Open Source
        </Typography>
      </Box>
      <Box sx={{ marginTop: { xs: 1, sm: 2, md: 4 }, textAlign: "center" }}>
        <Typography
          sx={{
            fontFamily: "Roboto Condensed",
            color: "#163BBF",
            fontWeight: 400,
            fontSize: { xs: "13px", sm: "12px", md: "25px" },
            color: "#777777",
          }}
        >
          Except the system that builds this site which is internal to Anant.
        </Typography>
        <Typography
          sx={{
            margin: { xs: 1, md: 2 },
            fontFamily: "Roboto Condensed",
            color: "#163BBF",
            fontWeight: 400,
            fontSize: { xs: "16px", sm: "12px", md: "25px" },
            color: "#383D3B",
          }}
        >
          We have open sourced parts of it at the{" "}
          <a href="https://github.com/Appleseed">Appleseed Project</a>.
        </Typography>
      </Box>
    </Box>
  )
}

export default OpenSourceSection
