import React from "react"
import { ListItem, List, ListItemIcon, Typography } from "@mui/material"
import { BsFillStarFill } from "react-icons/bs"
import { Link } from "gatsby"
function ListItemsSection({ listItems }) {
  return (
    <List sx={{ margin: "4px 0" }}>
      {listItems.map((item, index) => (
        <ListItem
          style={{
            paddingBottom: 0,
            paddingLeft: 0,
            marginBottom: 0,
          }}
          key={index}
        >
          {item.link.startsWith("http") ? (
            <a
              href={item.link}
              target="_blank"
              rel="noopener"
              underline="none"
              style={{
                display: "flex",
                alignItems: "center",
                "&:hover": { textDecoration: "underline" },
                textDecoration: "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  marginRight: { xs: "14px", sm: "8px" },
                }}
              >
                <BsFillStarFill
                  style={{
                    fontSize: "14px",
                    color: "#FFA62B",
                    "&:hover": { color: "#163BBF" },
                  }}
                />
              </ListItemIcon>
              <Typography
                sx={{
                  fontSize: { xs: "18px", sm: "10px", lg: "25px" },
                  color: "black",
                  fontFamily: "'Roboto Condensed', sans-serif",
                  "&:hover": { textDecoration: "underline" },
                  textDecoration: "none",
                }}
              >
                {item.title}
              </Typography>
            </a>
          ) : (
            <a
              href={`#${item.link}`}
              scroll={{ behavior: "smooth" }}
              underline="none"
              style={{
                display: "flex",
                alignItems: "center",
                "&:hover": { textDecoration: "underline" },
                textDecoration: "none",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "auto",
                  marginRight: { xs: "14px", sm: "8px" },
                }}
              >
                <BsFillStarFill
                  style={{
                    fontSize: "14px",
                    color: "#FFA62B",
                    "&:hover": { color: "#163BBF" },
                  }}
                />
              </ListItemIcon>
              <Typography
                sx={{
                  color: "black",
                  fontSize: { xs: "18px", sm: "10px", lg: "25px" },
                  fontFamily: "'Roboto Condensed', sans-serif",
                  "&:hover": { textDecoration: "underline" },
                  textDecoration: "none",
                }}
              >
                {item.title}
              </Typography>
            </a>
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default ListItemsSection
