import React from "react"
import { Box, Grid, Typography } from "@mui/material"

function MissionAndValueCard({ Icon, title }) {
  return (
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          height: { sm: "125px", lg: "300px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingX: { xs: "21px", sm: "22px", lg: "70px" },
          paddingY: { xs: "32px", sm: "22px", lg: "50px" },
          boxShadow: " 3px 4px 6px 2px rgba(0, 0, 0, 0.25)",
          transition: "0.3s all ease-in",
          borderRadius: "8px",
          borderBottom: {
            xs: "7px solid transparent",
            lg: "10px solid transparent",
          },
          ":hover": { borderBottomColor: "#FFA62B" },
        }}
      >
        <Box
          sx={{
            height: { xs: "38px", sm: "25px", lg: "55px" },
            width: { xs: "50px", sm: "25px", lg: "70px" },
            marginBottom: { xs: 2, lg: 5 },
            color: "#FFA62B",
            ":hover": { color: "#163BBF" },
          }}
        >
          <Icon style={{ width: "inherit", height: "inherit" }} />
        </Box>
        <Typography
          sx={{
            fontWeight: 550,
            fontSize: { xs: "13px", sm: "11px", lg: "25px" },
            color: "#383D3B",
            fontFamily: "Roboto consended, sans-serif",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Grid>
  )
}

export default MissionAndValueCard
