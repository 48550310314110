import React from "react"
import { Grid, Typography, Container, Button, Box } from "@mui/material"
import { RiArrowRightCircleLine } from "@react-icons/all-files/ri/RiArrowRightCircleLine"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
const TrainingSection = () => {
  return (
    <Grid
      sx={{
        background: "linear-gradient(270deg, #163BBF 50.35%, #383D3B 100%)",
        paddingY: { xs: 3, sm: 0, lg: 2.5 },
      }}
    >
      <Container maxWidth="xl" className="training_section_container">
        <Box>
          <Grid
            container
            sx={{
              paddingY: 3,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              style={{ maxWidth: { xs: "100%", md: "40%" } }}
            >
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: { xs: 20, sm: 25, lg: 50 },
                  textAlign: { xs: "center", sm: "start" },
                  textTransform: { xs: "uppercase", sm: "capitalize" },
                }}
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={700}
              >
                LLM Chatbot with Apache Cassandra?
              </Typography>
              <Typography
                variant="h6"
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={400}
                component="h2"
                sx={{
                  marginTop: { xs: 2, sm: 1 },
                  color: "#FFBF66",
                  textTransform: "uppercase",
                  fontSize: { xs: 16, sm: 12, lg: 32 },
                  textAlign: { xs: "center", sm: "start" },
                }}
              >
                Vector Search is coming to Apache Cassandra!
              </Typography>
              <Box
                sx={{
                  display: { xs: "flex", sm: "none" },
                  justifyContent: "center",
                  marginY: 3,
                }}
              >
                <StaticImage
                  style={{ width: "70%" }}
                  src="../../images/Illustration.png"
                  alt="Illustration Image"
                />{" "}
              </Box>
              <Typography
                sx={{
                  marginTop: { xs: 5, sm: 1, lg: 3 },
                  color: "#FFF",
                  fontSize: { xs: 16, sm: 10, md: 25 },
                }}
                fontFamily="Roboto Condensed, sans-serif"
                fontWeight={400}
              >
                We are partnering with Datastax to put on an in-person LLM
                Bootcamp that covers Strategy and Theory, NoCode/ Code LLM App
                Stacks, and an hands on workshop to build you own Cassandra
                powered Chatbot with GPT/LLM.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "start" },
                  marginTop: { xs: "10px", sm: "14px", md: "30px" },
                }}
              >
                <Link to="https://kono.io/bootcamp/">
                  <Button
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "#FFA62B",
                      fontSize: { xs: "14px", sm: "10px", md: "18px" },
                      textTransform: "uppercase",
                      "&:hover": {
                        backgroundColor: "#FFBF66",
                      },
                    }}
                    fontFamily="Roboto Condensed, sans-serif"
                    fontWeight={700}
                    variant="contained"
                    endIcon={<RiArrowRightCircleLine size={"20px"} />}
                  >
                    Get Started
                  </Button>
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
                display: { xs: "none", sm: "flex" },
              }}
            >
              <StaticImage
                style={{ width: "70%" }}
                src="../../images/Illustration.png"
                alt="Illustration Image"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}

export default TrainingSection
