import React from "react"
import { Typography, Grid, Box } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import ListItemsSection from "./AboutUsInfoList"

const AboutUsInfo = () => {
  const listItems = [
    {
      title: "Mission and Values",
      link: "missionAndValues",
    },
    {
      title: "Our Team",
      link: "ourTeam",
    },
    {
      title: "Contribute now!",
      link: "https://github.com/Anant/awesome-cassandra",
    },
  ]
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                height: { xs: "29px", sm: "22px", md: "76px" },
                width: { xs: "42px", sm: "40px", md: "109px" },
                marginBottom: { xs: "15px", sm: "20px", lg: 0 },
              }}
            >
              <StaticImage
                src={"../../../images/Logo.svg"}
                alt="Logo"
                objectFit="contain"
                style={{
                  width: "100%",
                  height: "100%",
                  "@media screen and (min-width: 768px)": {
                    width: "40px",
                    height: "22px",
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex" }}>
            <Typography
              sx={{
                marginRight: 1,
                fontFamily: "Roboto Condensed",
                fontWeight: 700,
                color: "#383D3B",
                fontSize: { xs: "25px", sm: "32px", lg: "80px" },
              }}
            >
              Get to
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto Condensed",
                color: "#163BBF",
                fontWeight: 700,
                fontSize: { xs: "25px", sm: "32px", lg: "80px" },
              }}
            >
              Know Us!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ListItemsSection listItems={listItems} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} marginY={{ xs: "50px", md: 0 }}>
        <Box sx={{ width: { xs: "100%", sm: "85%" }, mx: "auto" }}>
          <Typography
            sx={{
              fontFamily: "Roboto condensed, sans-serif",
              fontWeight: 700,
              fontSize: { xs: "25px", sm: "16px", lg: "40px" },
              color: "#383D3B",
              marginBottom: 4,
            }}
          >
            Who are we ?
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto condensed, sans-serif",
              fontWeight: 500,
              color: "#535A57",
              marginBottom: { xs: 2, md: 4 },
              lineHeight: { sm: "13px", lg: "37px" },
              fontSize: { xs: "13px", sm: "11px", lg: "32px" },
            }}
          >
            Welcome to{" "}
            <a to="/" style={{ color: "blue", cursor: "pointer" }}>
              Cassandra Link
            </a>
            , where{" "}
            <a
              style={{ cursor: "pointer", color: "#383D3B", fontWeight: 550 }}
              to="#ourTeam"
            >
              our team of Cassandra experts
            </a>{" "}
            have curated a knowledge base of videos, articles, and open source
            projects to help developers, administrators, and architects master
            Apache Cassandra.
          </Typography>
          <Typography
            sx={{
              fontFamily: "Roboto condensed, sans-serif",
              fontWeight: 500,
              color: "#535A57",
              lineHeight: { sm: "13px", lg: "37px" },
              fontSize: { xs: "13px", sm: "11px", lg: "32px" },
            }}
          >
            We're passionate about sharing our knowledge and contributing to the
            Cassandra community, and we're excited to have you join us on this
            journey of learning and discovery.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
export default AboutUsInfo
