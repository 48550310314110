import { Grid, Container } from "@mui/material"
import React from "react"
import GetToKnowUs from "../components/AboutUs/Info/GetToKnowUs"
import ContributingSection from "../components/AboutUs/Contribute/ContributingSection"
import aboutUsHeaderBackgroundImage from "../images/AboutUsHeaderBG.png"
import ContributeBackgroundImage from "../images/ContributeBG.png"
import OpenSourceSection from "../components/ContactUs/OpenSourceSection"
import MissionAndValuesSection from "../components/AboutUs/MissionAndValues/MissionAndValuesSection"
import TrainingSection from "../components/TrainingSection/TrainingSection"
function AboutUsLayout() {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(${aboutUsHeaderBackgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          marginBottom: { sm: "50px" },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ marginY: { xs: "32px", sm: "50px", lg: "150px" } }}
        >
          <GetToKnowUs />
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Container
          maxWidth="xl"
          sx={{ marginBottom: { xs: "60px", lg: "120px" } }}
        >
          <MissionAndValuesSection />
        </Container>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          backgroundImage: `url(${ContributeBackgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <ContributingSection />
      </Grid>
      <Grid item xs={12}>
        <TrainingSection />
      </Grid>
    </Grid>
  )
}

export default AboutUsLayout
