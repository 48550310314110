import React from "react"
import Layout from "../components/Layout/Layout"
import AboutUsLayout from "../layouts/AboutUsLayout"
import { Helmet } from "react-helmet"

const About = () => {
  return (
    <Layout>
      <Helmet>
        <title> About - Cassandra Link </title>
        <meta
          name="description"
          content="Learn more about Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:title" content="Cassandra Link - About" />
        <meta
          property="og:description"
          content="Learn more about Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/about" />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cassandra Link - About" />
        <meta
          name="twitter:description"
          content="Learn more about Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "About Us - Cassandra Link",
            keywords: "Cassandra Link, About Us",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        {/* Open Graph */}
        <meta property="og:title" content={"About Us - Cassandra Link"} />

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      <AboutUsLayout />
    </Layout>
  )
}

export default About
