import React from "react"
import { HiOutlineChartBar } from "react-icons/hi"
import { BsLightbulb, BsBookHalf } from "react-icons/bs"
import { Grid, Typography } from "@mui/material"
import MissionAndValueCard from "./MissionAndValueCard"
const MissionAndValuesSection = () => {
  const MissionAndValuesData = [
    {
      icon: BsBookHalf,
      title:
        "Build a highly curated knowledge base and news feed related to Apache Cassandra",
    },
    {
      icon: HiOutlineChartBar,
      title:
        "Empower developers and organizations with resources for high-performance applications.",
    },
    {
      icon: BsLightbulb,
      title:
        "Drive innovation by pushing boundaries and enhancing performance of Cassandra platform.",
    },
  ]
  return (
    <Grid container>
      <Grid item xs={12} id="missionAndValues">
        <Typography
          sx={{
            fontSize: { xs: "25px", sm: "16px", lg: "40px" },
            textTransform: "uppercase",
            fontWeight: 700,
            color: "#163BBF",
            fontFamily: "Roboto condensed, sans-serif",
            marginBottom: { sm: 1.5, lg: 4 },
          }}
        >
          mission and values
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "13px", sm: "11px", lg: "25px" },
            width: { sm: "45%", lg: "50%" },
            fontWeight: 400,
            color: "#383D3B",
            fontFamily: "Roboto condensed, sans-serif",
          }}
        >
          Apache Cassandra is one of the most forward looking Databases in the
          world. It is designed for now and the future.{" "}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "50px" }}>
        <Grid container spacing={4}>
          {MissionAndValuesData.map((item) => (
            <MissionAndValueCard
              key={item.title}
              Icon={item.icon}
              title={item.title}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MissionAndValuesSection
